import { graphql } from 'gatsby'
import React from 'react'
import { SeoData } from '../../../../src/common/types/Seo'
import { MenuPrincipal } from '../../../../src/specific/constants/menu'
import { useSelectedMenu } from '../../../../src/specific/helpers/customHooks/useSelectedMenu'
import Layout from '../../../../src/common/components/Layout'
import { locale } from '../../../../src/common/i18n'
import { t } from '../../../../src/common/i18n/index'
import styles from './index.module.scss'

interface Props {
  data: {
    seoData: SeoData
  }
}
const recursosHumanos = ({ data }: Props) => {
  useSelectedMenu(MenuPrincipal.HOME)
  return (
    <Layout selector={false} seoData={data.seoData}>
      <div className={styles.rrhh}>
        <div className={styles.rrhh_title_cont}>
          <h1 className={styles.rrhh_title}>{t('recursos-humanos.titulo')}</h1>
          <h2 className={styles.rrhh_subtitle}>
            {t('recursos-humanos.bloque1.titulo1')}
          </h2>

          <div className={styles.rrhh_cont_images}>
            <div className={styles.rrhh_images}>
              <img
                src={
                  'https://media.rodi.es/estaticas/recursos-humanos/filo-1.png'
                }
                alt="FILOSOFÍA Y ESTRATEGIA"
                title="FILOSOFÍA Y ESTRATEGIA"
                width="100%"
              />
            </div>
            <div className={styles.rrhh_images}>
              <img
                src={
                  'https://media.rodi.es/estaticas/recursos-humanos/equpi_rodi.jpg'
                }
                alt="FILOSOFÍA Y ESTRATEGIA"
                title="FILOSOFÍA Y ESTRATEGIA"
                width="100%"
              />
            </div>
          </div>

          <div className={styles.rrhh_text_section}>
            <p className={styles.rrhh_text}>
              {t('recursos-humanos.bloque1.parte1.texto1')}
              <strong>
                {t('recursos-humanos.bloque1.parte1.texto2')}
              </strong>{' '}
              {t('recursos-humanos.bloque1.parte1.texto3')}
              <strong>
                {t('recursos-humanos.bloque1.parte1.texto4')}
              </strong>{' '}
              {t('recursos-humanos.bloque1.parte1.texto5')}
            </p>

            <div className={styles.rrhh_column_section}>
              <div className={styles.rrhh_column}>
                <ul>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque1.parte2.texto1')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque1.parte2.texto2')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque1.parte2.texto3')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque1.parte2.texto4')}
                  </li>
                </ul>
              </div>
              <div className={styles.rrhh_column}>
                <ul>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque1.parte3.texto1')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque1.parte3.texto2')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque1.parte3.texto3')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque1.parte3.texto4')}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className={styles.rrhh_banner}>
            {locale === 'es' ? (
              <img
                src={
                  'https://media.rodi.es/estaticas/recursos-humanos/claim.png'
                }
                alt="FILOSOFÍA Y ESTRATEGIA"
                title="FILOSOFÍA Y ESTRATEGIA"
                width="100%"
              />
            ) : (
              <img
                src={
                  'https://media.rodi.es/estaticas/recursos-humanos/claim_ca.png'
                }
                alt="FILOSOFÍA Y ESTRATEGIA"
                title="FILOSOFÍA Y ESTRATEGIA"
                width="100%"
              />
            )}
          </div>
        </div>

        <div className={styles.rrhh_title_cont}>
          <h2 className={styles.rrhh_subtitle}>
            {t('recursos-humanos.bloque2.titulo1')}
          </h2>

          <div className={styles.rrhh_cont_images}>
            <div className={styles.rrhh_images}>
              <img
                src={
                  'https://media.rodi.es/estaticas/recursos-humanos/equipo_rodi_taller.jpg'
                }
                alt="EL EQUIPO"
                title="EL EQUIPO"
                width="100%"
              />
            </div>
            <div className={styles.rrhh_images}>
              <img
                src={
                  'https://media.rodi.es/estaticas/recursos-humanos/equipo_rodi_central.jpg'
                }
                alt="EL EQUIPO"
                title="EL EQUIPO"
                width="100%"
              />
            </div>
          </div>
          <div className={styles.rrhh_text_section}>
            <p className={styles.rrhh_text}>
              <strong>{t('recursos-humanos.bloque2.parte1.texto1')}</strong>
            </p>

            <div className={styles.rrhh_column_section}>
              <div className={styles.rrhh_column}>
                <p className={styles.rrhh_text_column}>
                  {t('recursos-humanos.bloque2.parte2.texto1')}
                  <strong>
                    {t('recursos-humanos.bloque2.parte2.texto2')}
                  </strong>{' '}
                  {t('recursos-humanos.bloque2.parte2.texto3')}
                  <strong>{t('recursos-humanos.bloque2.parte2.texto4')}</strong>
                  {t('recursos-humanos.bloque2.parte2.texto5')}
                  <strong>
                    {' '}
                    {t('recursos-humanos.bloque2.parte2.texto6')}{' '}
                  </strong>
                  {t('recursos-humanos.bloque2.parte2.texto7')}
                </p>
                <p className={styles.rrhh_text_column}>
                  {t('recursos-humanos.bloque2.parte2.texto8')}
                </p>
                <p className={styles.rrhh_text_column}>
                  {t('recursos-humanos.bloque2.parte2.texto9')}
                </p>
              </div>
              <div className={styles.rrhh_column}>
                <ul>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque2.parte3.texto1')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque2.parte3.texto2')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque2.parte3.texto3')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque2.parte3.texto4')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque2.parte3.texto5')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque2.parte3.texto6')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque2.parte3.texto7')}
                  </li>
                  <li className={styles.rrhh_list_item}>
                    {t('recursos-humanos.bloque2.parte3.texto8')}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rrhh_title_cont}>
          <h2 className={styles.rrhh_subtitle}>
            {t('recursos-humanos.bloque3.titulo1')}
          </h2>

          <div className={styles.rrhh_cont_images}>
            <div className={styles.rrhh_images}>
              <img
                src={
                  'https://media.rodi.es/estaticas/recursos-humanos/formacio_dual_recepcion_rodi..jpg'
                }
                alt="FORMACIÓN DUAL"
                title="FORMACIÓN DUAL"
                width="100%"
              />
            </div>
            <div className={styles.rrhh_images}>
              <img
                src={
                  'https://media.rodi.es/estaticas/recursos-humanos/formacion_dual_rodi.jpg'
                }
                alt="FORMACIÓN DUAL"
                title="FORMACIÓN DUAL"
                width="100%"
              />
            </div>
          </div>
          <div className={styles.rrhh_text_section}>
            <p className={styles.rrhh_text}>
              <strong>{t('recursos-humanos.bloque3.parte1.texto1')}</strong>
            </p>

            <div className={styles.rrhh_column_section}>
              <div className={styles.rrhh_column}>
                <p className={styles.rrhh_text_column}>
                  {t('recursos-humanos.bloque3.parte2.texto1')}
                </p>
                <p className={styles.rrhh_text_column}>
                  {t('recursos-humanos.bloque3.parte2.texto2')}
                </p>
              </div>
              <div className={styles.rrhh_column}>
                <p className={styles.rrhh_text_column}>
                  {t('recursos-humanos.bloque3.parte3.texto1')}
                </p>
                <p className={styles.rrhh_text_column}>
                  {t('recursos-humanos.bloque3.parte3.texto2')}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rrhh_title_cont}>
          <h2 className={styles.rrhh_subtitle}>
            {t('recursos-humanos.bloque4.titulo1')}
          </h2>

          <div className={styles.rrhh_cont_images}>
            <div className={styles.rrhh_images}>
              <img
                src={
                  'https://media.rodi.es/estaticas/recursos-humanos/formacio_dual_rodi-copia.jpg'
                }
                alt="PROGRAMA DE PRÁCTICAS"
                title="PROGRAMA DE PRÁCTICAS"
                width="100%"
              />
            </div>
            <div className={styles.rrhh_images}>
              <img
                src={
                  'https://media.rodi.es/estaticas/recursos-humanos/equipo_rodi.jpg'
                }
                alt="PROGRAMA DE PRÁCTICAS"
                title="PROGRAMA DE PRÁCTICAS"
                width="100%"
              />
            </div>
          </div>
          <div className={styles.rrhh_text_section}>
            <p className={styles.rrhh_text}>
              {t('recursos-humanos.bloque4.parte1.texto1')}
            </p>

            <div className={styles.rrhh_column_section}>
              <div className={styles.rrhh_column}>
                <ul>
                  <li className={styles.rrhh_list_item}>
                    <strong>
                      {t('recursos-humanos.bloque4.parte2.texto1')}
                    </strong>
                    : {t('recursos-humanos.bloque4.parte2.texto2')}
                  </li>
                </ul>
              </div>
              <div className={styles.rrhh_column}>
                <ul>
                  <li className={styles.rrhh_list_item}>
                    <strong>
                      {t('recursos-humanos.bloque4.parte3.texto1')}
                    </strong>
                    : {t('recursos-humanos.bloque4.parte3.texto2')}&nbsp;
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default recursosHumanos

export const pageQuery = graphql`
  query recursosHumanosQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
